<template>
  <div class="outside">
    <!-- <banner /> -->
    <div class="top-banner">
      <img src="../../assets/img/repairMaterials/banner.jpg" alt="" srcset="">
    </div>
    <div class="introduction">
      <div class="edition_heart">
        <div class="title">文物保护修复材料介绍</div>
        <div class="titleEng">Cultural relic protection and restoration materials</div>
        <el-row>
          <el-col :span="24">
            <div class="content">
              近年来，中国文化遗产保护事业迅速发展。中国文物保护领域积极主动地了解国际同行所使用的文物保护材料，掌握当前文物保护材料发展趋势。在文物保护和
              修复过程中，认知文物本体材质和病害，有针对性的应用和研发适当的文物保护材料。
            </div>
            <div class="content">
              目前文物保护材料根据不同文物特征和使用需求更新迭代较快。山东文保在多年的文物保护修复实践当中秉持“传统与创新并行”的原则，于保护材料筛选和使用
              方面不断摸索，在操作性能、化学稳定性、可再处理性、职业健康、绿色环保等多方面对各种常用的馆藏文物保护材料进行衡量比对和经验积累。
              摸索出了一套完整
              的馆藏文物保护修复材料体系和使用技法，具有耐老化、低残留、绿色安全、易操作、可去除等特点。
              能很好地符合馆藏文物保护修复中最小介入、可再操作、可识
              别三原则。山东文保完整的材料体系与使用技法已成功让数以千计的各类文物得到了合理的保护修复。
            </div>
          </el-col>
        </el-row>

        <div class="photos">
          <el-row>
            <el-col :span="8" v-for="(it, index) in introductionImg" :key="index">
              <img :src="it.imgUrl" alt="" />
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="repair_case">
      <div class="edition_heart">
        <div class="title">材料介绍</div>
        <div class="titleEng">Material Introduction</div>
        <div class="materials">
          <div class="materials_left">
            <div class="materials_title">
              {{ materialsData[materialsIndex].title }}
            </div>

            <div class="materials_content" v-html="materialsData[materialsIndex].content.slice(0, 105) + '......'"></div>
            <div class="materials_content2" v-html="materialsData[materialsIndex].content.slice(0, 25) + '......'"></div>
            <div class="more">
              <el-button plain class="btn" @click="materialsDetailes">更多</el-button>
            </div>
          </div>

          <div class="photos">
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>
            <swiper ref="mySwiper" :options="swiperOptions" style="width: 100%; height: 360px;" :autoplay="true">
              <swiper-slide v-for="(item, index) in materialsData" :key="index"
                style="height: 270px; display: flex;   align-items: center;">
                <div class="repair_case_photos" @click="changeMaterials(index)">
                  <img ref="swiperImg" :src="item.imgUrl" />
                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="materialsOpen">
      <div class="materials_show">
        <div>
          <div class="materials_title">{{ materialsData[materialsIndex].title }}</div>
          <img ref="swiperImg" :src="materialsData[materialsIndex].imgUrl" class="swiper1" />
          <div class="materials_content" v-html="materialsData[materialsIndex].content"></div>
        </div>
        <img ref="swiperImg" :src="materialsData[materialsIndex].imgUrl" class="swiper2" />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import banner from '/src/components/banner.vue'
// vue-awesome-swiper@4.x 版本首字母 S 要用大写
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.min.css'

export default {
  name: 'RepairProcessDetails',
  components: { banner, Swiper, SwiperSlide },
  data() {
    return {
      introductionImg: [
        {
          imgUrl: require('/src/assets/img/repairMaterials/materials12.jpg'),
        },
        {
          imgUrl: require('/src/assets/img/repairMaterials/materials13.jpg'),
        },
        {
          imgUrl: require('/src/assets/img/repairMaterials/materials14.jpg'),
        },
      ],
      materialsData: [
        {
          imgUrl: require('/src/assets/img/repairMaterials/materials1.png'),
          title: '金牌瓷器水性修复釉产品介绍',
          content:
            '金牌瓷器水性修复轴是专门为瓷器修复者制造的专业修复材料。</br> 金牌是应诸多瓷器修复公司和博物馆的要求，专门设计生产的瓷器修复釉。这种产品替代了以前所使用的溶剂基修复釉，使瓷器修复工作变得更加简单，客户想要的是一种水性的，快干的，并且能形成坚硬的以及不 黏的表面涂层材料。这种材料必须耐整，并且可以反复多种方式喷涂，比如，用画笔或喷笔。亮光版金牌修复釉，可以带来高光效果，以符合那些在高温下生产的瓷器的光亮效果。而亚光金牌修复釉，在不影响釉下色彩的同时，给人莹润的感觉。和很多其他的修复材料一样，金牌修复釉也具有很好的可逆性。',
        },
        {
          imgUrl: require('/src/assets/img/repairMaterials/materials2.png'),
          title: '西马斯达专业2型冷修复釉产品介绍',
          content:
            '专业2型是最新配方的专业冷修复釉 </br> 一一最平滑，最坚硬的釉层 一一可使用喷笔喷涂 一一可与其它版本的西马斯达冷修复釉和其它材料混合使用 一一可以提供高亮度表面</br> 使用混合比例：4：1； 可根据喷、涂需要添加稀释剂西马斯达专业2型冷修复釉是一种被专业界拥有广泛赞誉的冷修复系统，它是为了博物馆和专业陶瓷修复工作室专业设计生产的。它凝固后会形成一种坚硬、稳固兼且不氧化不发黄的涂层。专业2型冷修复釉会形成一层平滑的亮光层。我们的测试显示， 西马斯达专业2型冷修复釉在60℃的水中连续刷洗20遍后无任何损坏情况。制作亚光效果 </br>如果需要亚光效果，需要把西马斯达亚光剂混合到修复釉里。混合比例：70g-80g亚光剂混合于1升的西马斯达专业2型修复釉里。</br>制作白釉效果</br>白釉效果可以通过两种方法制作：在西马斯达专业2型修复釉里加入10%西马斯达CGW糖白釉，就可以达到一种高品质，玉质感强，无杂质的白釉。或者加入一些钛白粉。',
        },
        {
          imgUrl: require('/src/assets/img/repairMaterials/materials3.png'),
          title: '西马斯达80°C低温修复釉产品介绍',
          content:
            '西马斯达低温修复釉是一种完全无色透明，不发黄，在加温时无异味的超低温修复釉，西马斯达低温修复釉一般在80C下至30分钟即可给我们带来高强度表面附着力的优异的硬质光泽光洁度；高光亮度的釉层。</br> 可根据喷、涂需要添加稀释剂 </br> 我们也可以把西马斯达低温修复釉制成亚光质感的材料，我们可以通过加入一定量的亚光剂来达到所需的 效果。70-80克亚光剂与一升超低温修复釉混合。低温亚光釉里也可加入任何的色粉或者色素，使用粉状颜料 来代替膏状颜料的好处在于它可以保持釉质的高强度等优良特征。',
        },
        {
          imgUrl: require('/src/assets/img/repairMaterials/materials4.png'),
          title: '阿塔克2000N型陶瓷粘合剂',
          content:
            '阿塔克2000N型陶瓷粘合剂是专门为了粘合古陶瓷例如古陶瓷或其他疏松材料而设计生产的粘合剂。它含有100%高纯度的硝酸纤维素。完全不添加任何软化剂，因此它的耐久度能大大提高。 </br> 阿塔克2000N型黏合剂的可逆性极佳，我们可以在任何时侯用丙酮，乙酸乙酯、甲苯等溶剂将其溶解。',
        },
        {
          imgUrl: require('/src/assets/img/repairMaterials/materials5.png'),
          title: '爱劳达2020环氧树脂套装',
          content:
            '爱劳达是一种为粘合玻璃和陶瓷而生产的全透明无色环氧树脂。它表面强度高，耐久度极佳。 完全不变黄，粘稠度低，室温凝固。它的使用比例为重量100树脂比30硬化剂。剪切强度17N/mm平方。在室温下， 2020的工作时间为45分钟，只需要24小时就可以上手，三天后完全凝固，爱劳达2020粘合剂的稳定性和易操作性是在所有专用于陶瓷粘合的粘合剂中屈指可数的佳品。它是大英博物馆推称的四种专业粘合剂之一。</br> 爱劳达2020环氧树脂套装保存期2年。',
        },
        {
          imgUrl: require('/src/assets/img/repairMaterials/materials6.png'),
          title: '卡麦玻璃颜料',
          content:
            '成份：彩色玻璃粉</br> 规格：15um</br> 种类：玻璃搪瓷白、玻璃淡黄色、玻璃古金色、玻璃朱红色、玻璃鸭蛋绿、玻璃皇家（安娜）绿、玻璃苔藓绿、 玻璃金褐色、玻璃珊瑚红、玻璃橙色、玻璃紫红、玻璃紫晶蓝、玻璃淡蓝、玻璃绿玉色、玻璃深蓝、玻璃黑。',
        },
        {
          imgUrl: require('/src/assets/img/repairMaterials/materials7.png'),
          title: '卡麦14250陶瓷修复用天然矿物色素套装',
          content:
            '成份：25种天然矿物色素</br> 包装：色素装置于25毫升玻璃瓶中，外套木盒 </br> 品种：23010酞菁绿带微黄、23178 IRGAZIN牌橙色DPP RA、23180 IRGAZIN牌红色DPP BO、23182 IR GAZIN牌红宝石红DPP TR、23300浅永黄、23350防印第安黄、23610深茜红、23720 Hostaperm 牌红、40220浅金赭石、40400原赭石意大利产、40430烧赭石3号、40510威尼斯红、40612原琥珀意大利产、40710烧琥珀塞浦路斯产、40810波西米亚土绿、44100钴绿、44250草绿/鲜绿、45000 深蓝、45200波斯蓝、45350钴紫、45730钴天蓝、45750浅钴宝石蓝、46300锌白无铅、 47100骨黑、47250炉黑、73054亚麻籽油冷压缩、79200卡麦快干媒介剂。',
        },
        {
          imgUrl: require('/src/assets/img/repairMaterials/materials8.png'),
          title: '西马斯达A+B环氧树脂膏',
          content:
            '性状：灰色固体</br>使用比例：1：1糅合</br>操作时间：30分钟（@25°C）</br>凝固时间：6小时（@25°C)</br>特点：凝固后硬度高，可加工，易延展，光洁度好，附着力强，不开裂。',
        },
        {
          imgUrl: require('/src/assets/img/repairMaterials/materials9.png'),
          title: '超白型密力胶',
          content:
            '性状：A组份白色膏体B组份灰色膏体</br>凝固后性状：锌白色固体</br>操作时间：30分钟（@25°C）</br>凝固时间：120分钟（@25°C）</br>完全凝固时间：24小时（@25°C）</br>特点：硬度高、耐磨性能佳、耐温性能佳。',
        },
        {
          imgUrl: require('/src/assets/img/repairMaterials/materials10.png'),
          title: '格姆斯特清洁膏产品介绍',
          content:
            '性状：浅褐色胶状膏体</br>包装：250g</br>使用方法：在需清洁表面擦拭</br>清洁原理：格姆斯特带有细微的正电极，因此可以吸附外来颗粒。石粉、煤 、木炭、石英、干燥的色素、霉 菌、灰尘、油脂等可以被轻易地 吸附到格姆斯特上。</br>特点：免溶液、污渍不扩散、可反复使用、 清洁效果明显。',
        },
        {
          imgUrl: require('/src/assets/img/repairMaterials/materials11.png'),
          title: '佩利姆固体清洁膏',
          content:
            '性状：浅灰色膏体</br>用途：陶瓷器表面硬结物清除</br>包装：250ml</br>使用方法：在需清洁表面涂刷，待溶剂油挥发后，将残留的硅晶涂清除。',
        },
      ],
      materialsIndex: 0,
      materialsOpen: false,
      isMounted: false,
      swiperOptions: {
        slidesPerColumnFill: 'row',
        slidesPerView: 3, //一行显示3个
        spaceBetween: 10, //间隔10
        slidesPerGroup: 2,
        freeMode: true,
        speed: 1000, //滑动速度
        // 设置点击箭头
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        // loop: true,
        // autoplay: {
        //   //这个参数很重要,当外力干扰轮播图时，它能够继续实现自我滚动
        //   disableOnInteraction: false,
        //   delay: 5000, //5秒切换一次
        // },
      },
    }
  },
  mounted() {
    this.isMounted = true
  },
  computed: {
    customswiper() {
      let swiper
      if (this.isMounted) swiper = this.$refs.mySwiper.$swiper
      return swiper
    },
  },
  methods: {
    changeMaterials(index) {
      this.materialsIndex = index
    },
    materialsDetailes() {
      this.materialsOpen = true
    },
  },
}
</script>

<style lang="scss" scoped>
.outside {
  background-color: #F9F7F3;
}

.top-banner {
  width: 100%;
  height: 400px;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    min-width: 1920px;
    height: 400px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media screen and (min-width: 921px) {
  .edition_heart {
    width: 62.5%;
  }

  .outside {
    padding-top: 120px;

    .introduction {
      .title {
        padding-top: 80px;
      }
    }
  }

  .repair_case {
    .title {
      padding-top: 50px;
      font-size: 32px;
    }

    .swiper-button-next {
      top: 30px;
    }

    .swiper-button-prev {
      top: 30px;
      left: 95%;
    }

    .swiper_slide {
      height: 270px;
      display: flex;
      align-items: center;
    }

    .repair_case_photos {
      padding: 87px 0 95px;
    }

    .photos {
      padding-bottom: 95px;
    }

    .swiper-button-next {
      position: absolute;
      top: -40px;
    }

    .swiper-button-prev {
      position: absolute;
      top: -40px;
      left: 93.4%;
    }

    .materials_left {
      .materials_title {
        font-size: 24px;
      }

      .materials_content {
        font-size: 14px;
      }

      .materials_content2 {
        display: none;
      }

      .btn {
        width: 100px;
        height: 36px;
        font-size: 18px;
        margin-top: 20px;
      }
    }
  }

  .materials_show {
    display: flex;
    align-items: flex-start;
    justify-content: center;

    img {
      width: 56%;
    }

    .swiper1 {
      display: none;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 920px) {
  .edition_heart {
    width: 80%;
  }

  .outside {
    padding-top: 120px;

    .introduction {
      .title {
        padding-top: 80px;
      }
    }
  }

  .repair_case {
    .title {
      padding-top: 125px;
      font-size: 32px;
    }
  }

  .swiper-button-next {
    position: absolute;
    top: -40px;
  }

  .swiper-button-prev {
    position: absolute;
    top: -40px;
    left: 93.4%;
  }

  .materials_left {
    .materials_title {
      font-size: 24px;
    }

    .materials_content {
      font-size: 14px;
    }

    .materials_content2 {
      display: none;
    }

    .btn {
      width: 120px;
      height: 50px;
      font-size: 22px;
    }
  }

  .materials_show {
    display: flex;
    align-items: flex-start;
    justify-content: center;

    img {
      width: 56%;
    }

    .swiper1 {
      display: none;
    }
  }
}

@media screen and (max-width: 768px) {
  .top-banner {
    display: none;
  }

  .edition_heart {
    width: 90%;
  }

  .outside {
    padding-top: 60px;
  }

  .introduction {
    .title {
      padding-top: 20px;
    }
  }

  .edition_heart {
    padding-top: 130px;
  }

  .swiper-button-next {
    position: absolute;
    top: -40px;
  }

  .swiper-button-prev {
    position: absolute;
    top: -40px;
    left: 85%;
  }

  .materials_left {
    .materials_title {
      font-size: 14px;
    }

    .materials_content {
      display: none;
    }

    .materials_content2 {
      font-size: 12px;
    }

    .btn {
      width: 80px;
      height: 30px;
      font-size: 16px;
    }
  }

  .materials_show {
    img {
      width: 70%;
    }

    .swiper2 {
      display: none;
    }
  }

  ::v-deep .edition_heart {
    padding-top: 30px;
  }
}

* {
  margin: 0;
  padding: 0;
}

.outside {
  .introduction {
    width: 100%;

    .title {
      padding-top: 80px;
      font-size: 32px;
      font-family: PingFang SC, PingFang SC-Heavy;
      font-weight: 800;
      text-align: left;
      color: #333;
    }

    .titleEng {
      border-bottom: 1px solid #D8D8D8;
      padding-bottom: 12px;
      font-size: 14px;
      font-weight: 400;
      color: rgba(102, 102, 102, .4);
      line-height: 30px;
      padding-bottom: 18px;
      border-bottom: 1px solid #D8D8D8;
      position: relative;
    }

    .titleEng::before {
      content: "";
      display: block;
      width: 109px;
      height: 6px;
      background: #A40000;
      position: absolute;
      left: 0;
      bottom: 0;
    }

    .content {
      margin-top: 24px;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      text-align: left;
      color: #666;
      line-height: 30px;
      text-indent: 2em;
    }

    .photos {
      padding: 10px 0 10px 10px;
      width: 100%;
      height: 100%;
      margin: 20px 0 30px;

      img {
        width: 95%;
        height: auto;
        border: 5px solid rgba(255, 255, 255, 0.4);
      }
    }
  }

  .repair_case {
    width: 100%;
    color: #333;
    position: relative;

    .title {
      font-size: 32px;
      font-family: PingFang SC, PingFang SC-Heavy;
      font-weight: 800;
      text-align: left;
    }

    .titleEng {
      border-bottom: 1px solid #D8D8D8;
      padding-bottom: 12px;
      font-size: 14px;
      font-weight: 400;
      color: rgba(102, 102, 102, .4);
      line-height: 30px;
      padding-bottom: 18px;
      border-bottom: 1px solid #D8D8D8;
      position: relative;
    }

    .titleEng::before {
      content: "";
      display: block;
      width: 109px;
      height: 6px;
      background: #A40000;
      position: absolute;
      left: 0;
      bottom: 0;
    }

    .materials {
      display: flex;

      // align-items: center;
      .materials_left {
        height: auto;
        display: flex;
        flex-direction: column;
        margin: 80px 23px 0 0;

        .materials_title {
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          text-align: left;
          color: #666;
          margin-bottom: 30px;
        }

        .materials_content {
          width: 100%;
          height: auto;
          font-family: PingFang SC, PingFang SC-Regular;
          text-align: left;
          color: #666;
          line-height: 30px;
          letter-spacing: 1px;
          overflow: hidden;
        }

        .materials_content2 {
          width: 100%;
          height: auto;
          font-family: PingFang SC, PingFang SC-Regular;
          text-align: left;
          color: #e6e6e6;
          line-height: 35px;
          letter-spacing: 1px;
          overflow: hidden;
        }

        .more {
          position: relative;

          // margin-top: 20px;
          .btn {
            color: #fff;
            background: #a40000;
            position: absolute;
            right: 5px;
            font-size: 16px;
          }
        }
      }

      .photos {
        padding-bottom: 95px;
        width: 66.6%;
        margin-top: 80px;
        --swiper-navigation-color: #333;
        /* 单独设置按钮颜色 */
        --swiper-navigation-size: 16px;
        /* 设置按钮大小 */
        position: relative;

        .repair_case_photos {
          width: 100%;
          cursor: pointer;

          img {
            width: 96%;
            height: auto;
          }

          img:hover {
            filter: alpha(Opacity=80);
            -moz-opacity: 0.8;
            opacity: 0.8;
          }
        }
      }
    }
  }

  // 版心
  .edition_heart {
    // width: 62.5%;
    margin: 0px auto;
    position: relative;
  }
}

/deep/.el-dialog {
  margin-top: 30vh !important;
  z-index: 999;

  .materials_show {
    img {
      // width: 56%;
      height: auto;
      margin-left: 20px;
      max-width: 280px;
    }

    .materials_title {
      font-size: 24px;
      font-family: PingFang SC, PingFang SC-Bold;
      font-weight: 700;
      text-align: left;
      color: #333;
      margin-bottom: 40px;
    }

    .materials_content {
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      text-align: left;
      color: #666;
      line-height: 30px;
    }
  }
}
</style>
